import { UserToken } from "@tikifu/shared/types";
import { createContext, useContext, useState, ReactNode } from "react";

interface TokenContextProps {
  tiktokAccessToken: string | null;
  setTiktokAccessToken: (token: string | null) => void;
}

interface UserContextProps {
  user: UserToken | null;
  setUser: (token: UserToken | null) => void;
}

const TokenContext = createContext<TokenContextProps | undefined>(undefined);

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const TokenProvider = ({ children }: { children: ReactNode }) => {
  const [tiktokAccessToken, setTiktokAccessToken] = useState<string | null>(
    null,
  );

  return (
    <TokenContext.Provider value={{ tiktokAccessToken, setTiktokAccessToken }}>
      {children}
    </TokenContext.Provider>
  );
};

export const useTokenContext = () => {
  const context = useContext(TokenContext);
  if (!context) {
    throw new Error("useToken must be used within a TokenProvider");
  }
  return context;
};

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<UserToken | null>(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useToken must be used within a TokenProvider");
  }
  return context;
};
