import WebFont from "webfontloader";
import { TextProperties } from "@tikifu/shared/types.js";
import { TextState } from "../types";
import { CanvasObject } from "./CanvasObject";

export class TextObject extends CanvasObject {
  type = "TextObject";
  properties: TextProperties;
  state: TextState;

  constructor(texts: string[]) {
    super();
    this.properties = {
      texts: texts.map((text) => text.replace(/\\n/g, "\n")),
      text: texts[0] ?? "new text",
      x: 0,
      y: 0,
      fontSize: 0,
      fontFamily: "Roboto",
      fillStyle: "white",
      fontWeight: "500",
      strokeStyle: "black",
      lineWidth: 1,
      position: "bottom",
      bg: false,
      bgColor: "#ffff",
    };
    this.state = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      dragging: false,
      selected: false,
      editable: false,
    };
  }

  loadFont(callback: () => void): void {
    WebFont.load({
      google: {
        families: [`${this.properties.fontFamily}:300,400,500,600,700`],
      },
      active: () => {
        callback();
      },
      inactive: () => {
        console.error("Font loading failed");
      },
    });
  }

  draw(ctx: CanvasRenderingContext2D): void {
    const canvasWidth = ctx.canvas.width;
    const canvasHeight = ctx.canvas.height;

    if (this.properties.fontSize === 0) {
      this.state.width = 0.5 * canvasWidth;
      this.state.height = 0.5 * canvasWidth;
      this.properties.fontSize = 0.5 * canvasWidth;
    }

    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.direction = "inherit";
    ctx.fillStyle = this.properties.fillStyle;
    ctx.lineWidth = this.properties.lineWidth;
    ctx.strokeStyle = this.properties.strokeStyle;

    const lines = this.properties.text.split("\n");
    let fontSize = this.properties.fontSize;

    while (fontSize > 1) {
      ctx.font = `${this.properties.fontWeight} ${fontSize}px ${this.properties.fontFamily}`;
      const textMetrics = lines.map((line) => ctx.measureText(line));
      const textWidth = Math.max(
        ...textMetrics.map((metrics) => metrics.width),
      );
      const lineHeight = fontSize * 1.2;
      const textHeight = lineHeight * lines.length;

      if (textWidth <= this.state.width && textHeight <= this.state.height) {
        break;
      }
      fontSize -= 1;
    }

    this.properties.fontSize = fontSize;

    if (this.properties.position === "top") {
      this.properties.y = canvasHeight * 0.14;
      this.properties.x = canvasWidth / 2;
      this.state.y = this.properties.y;
      this.state.x = this.properties.x;
    } else if (this.properties.position === "bottom") {
      this.properties.y = canvasHeight * 0.86;
      this.properties.x = canvasWidth / 2;
      this.state.y = this.properties.y;
      this.state.x = this.properties.x;
    }

    this.properties.position = "";

    const lineHeight = this.properties.fontSize * 1.2;

    // Calculate the total text height, using ascent and descent for each line
    const textMetrics = lines.map((line) => ctx.measureText(line));
    let maxTextHeight = ((lines.length - 1) * lineHeight) / 2;

    for (const metrics of textMetrics) {
      maxTextHeight +=
        metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
    }

    const maxTextWidth = Math.max(
      ...textMetrics.map((metrics) => metrics.width),
    );

    // If background is enabled, draw it with padding
    if (this.properties.bg) {
      const padding = 5;
      const borderRadius = 5;
      const bgX = this.properties.x - maxTextWidth / 2 - padding;
      const bgY = this.properties.y - maxTextHeight / 2 - padding;
      const bgWidth = maxTextWidth + padding * 2;
      const bgHeight = maxTextHeight + padding * 2;

      ctx.fillStyle = this.properties.bgColor;

      ctx.beginPath();
      ctx.moveTo(bgX + borderRadius, bgY);
      ctx.lineTo(bgX + bgWidth - borderRadius, bgY);
      ctx.quadraticCurveTo(
        bgX + bgWidth,
        bgY,
        bgX + bgWidth,
        bgY + borderRadius,
      );
      ctx.lineTo(bgX + bgWidth, bgY + bgHeight - borderRadius);
      ctx.quadraticCurveTo(
        bgX + bgWidth,
        bgY + bgHeight,
        bgX + bgWidth - borderRadius,
        bgY + bgHeight,
      );
      ctx.lineTo(bgX + borderRadius, bgY + bgHeight);
      ctx.quadraticCurveTo(
        bgX,
        bgY + bgHeight,
        bgX,
        bgY + bgHeight - borderRadius,
      );
      ctx.lineTo(bgX, bgY + borderRadius);
      ctx.quadraticCurveTo(bgX, bgY, bgX + borderRadius, bgY);
      ctx.closePath();

      ctx.fill();
      ctx.fillStyle = this.properties.fillStyle;
    }

    lines.forEach((line, index) => {
      const y =
        this.properties.y +
        index * lineHeight +
        (textMetrics[index].actualBoundingBoxAscent -
          textMetrics[index].actualBoundingBoxDescent) /
          2 -
        ((lines.length - 1) * lineHeight) / 2;

      ctx.fillText(line, this.properties.x, y);
      ctx.strokeText(line, this.properties.x, y);
    });
  }

  containsPoint(x: number, y: number): boolean {
    const r =
      x >= this.properties.x - this.state.width / 2 &&
      x <= this.properties.x + this.state.width / 2 &&
      y >= this.properties.y - this.state.height / 2 &&
      y <= this.properties.y + this.state.height / 2;
    return r;
  }
}
