import { useEffect, useState } from "react";
import { CanvasData } from "../types";

const useCanvasRef = (canvasRef: React.MutableRefObject<CanvasData>) => {
  const [, setRender] = useState(0);

  useEffect(() => {
    const unsubscribe = canvasRef.current.events.subscribe(
      "canvasChanged",
      () => {
        setRender((prev) => prev + 1);
      },
    );
    return unsubscribe;
  }, [canvasRef]);

  return canvasRef.current.state;
};

export default useCanvasRef;
