import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Canvas from "../components/Canvas";
import TopBar from "../components/TopBar";
import { CanvasData, CanvasRefState } from "../types";
import useDraw from "../hooks/useDraw";
import { EventEmitter } from "../classes/EventEmitter";
import useCanvasState from "../hooks/useCanvasRef";

const Edit = () => {
  const canvasRef = useRef<CanvasData>({
    state: [],
    events: new EventEmitter(),
  });

  const [lastClickedObject, setLastClickedObject] = useState<symbol | null>(
    null,
  );
  const [lastActiveElement, setLastActiveElement] =
    useState<HTMLElement | null>(null);

  useCanvasState(canvasRef);

  const [draggedCanvas, setDraggedCanvas] = useState<symbol | null>(null);
  const [dragOffset, setDragOffset] = useState<number>(0);
  const [positions, setPositions] = useState<number[]>([]);
  const [transforms, setTransforms] = useState<Record<symbol, number>>({});
  const [transitions, setTransitions] = useState<Record<symbol, boolean>>({});
  const { id } = useParams();
  const draw = useDraw();

  {
    /*useEffect(() => {
    if (!id) return;

    getProject(id)
      .then((data) => {
        for (const slide of data.slides) {
          const newCanvasData: CanvasData = {
            id: Symbol(canvasData.current.length),
            dbId: slide.id,
            bgImg: new ImageObject(slide.paths, { x: 0, y: 0 }, "auto", "auto"),
            ref: createRef<HTMLCanvasElement>(),
            objects: slide.objects.map((obj) => {
              const textObj = new TextObject(obj.properties.texts);
              textObj.properties = obj.properties;
              return textObj;
            }),
          };
          canvasData.current.push(newCanvasData);
          newCanvasData.bgImg.state.img.crossOrigin = "anonymous";
          newCanvasData.bgImg.state.img.onload = () => {
            setRender([]);
          };
          newCanvasData.bgImg.state.img.src =
            newCanvasData.bgImg.properties.source;
        }
      })
      .catch((e: unknown) => {
        console.error(e);
      });
  }, [id]);*/
  }

  const handleMouseDown = (event: React.MouseEvent, data: CanvasRefState) => {
    for (const state of canvasRef.current.state) {
      const canvas = state.ref.current;
      if (!canvas) return;
      const rect = canvas.getBoundingClientRect();
      const threshold = canvas.width * 0.03;
      const scale = state.bgImg.state.img.naturalWidth / rect.width;
      const clickX = (event.clientX - rect.left) * scale;
      const clickY = (event.clientY - rect.top) * scale;
      if (
        state.objects.find((obj) => {
          obj.state.width += threshold;
          obj.state.height += threshold;
          const r = obj.containsPoint(clickX, clickY);
          obj.state.width -= threshold;
          obj.state.height -= threshold;
          return r;
        })
      ) {
        return;
      }
    }

    const draggedElement = data.ref.current;

    if (!draggedElement) return;

    setDraggedCanvas(data.id);
    setDragOffset(event.clientX - draggedElement.getBoundingClientRect().left); // Set initial mouse offset
    setPositions(
      canvasRef.current.state.map(
        (data) => data.ref.current?.getBoundingClientRect().left ?? 0,
      ),
    );
  };

  const handleMouseUp = () => {
    setDraggedCanvas(null);
    setDragOffset(0);
    setTransforms({});
    setTransitions({});
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (draggedCanvas === null) return;

    const draggedCanvasIndex = canvasRef.current.state.findIndex(
      (data) => data.id === draggedCanvas,
    );
    const deltaX = event.clientX - dragOffset - positions[draggedCanvasIndex];
    const draggedElement =
      canvasRef.current.state[draggedCanvasIndex].ref.current?.parentElement;
    if (!draggedElement) return;

    setTransforms((prev) => ({
      ...prev,
      [draggedCanvas]: deltaX,
    }));

    const draggedRect = draggedElement.getBoundingClientRect();

    canvasRef.current.state.forEach((data, i) => {
      if (data.id !== draggedCanvas) {
        const otherCanvas = data.ref.current;
        if (!otherCanvas) return;

        const otherRect = otherCanvas.getBoundingClientRect();

        if (
          ((draggedRect.right > otherRect.left + otherRect.width / 2 + 10 &&
            draggedCanvasIndex < i) ||
            (draggedRect.left < otherRect.right - otherRect.width / 2 - 10 &&
              draggedCanvasIndex > i)) &&
          otherRect.left === positions[i]
        ) {
          canvasRef.current.state = [...canvasRef.current.state]; // Copy the array to trigger re-render
          const temp = canvasRef.current.state[draggedCanvasIndex];
          canvasRef.current.state[draggedCanvasIndex] =
            canvasRef.current.state[i];
          canvasRef.current.state[i] = temp;
          setTransforms((prevTransforms) => ({
            ...prevTransforms,
            [draggedCanvas]: event.clientX - dragOffset - positions[i],
            [data.id]: positions[i] - positions[draggedCanvasIndex],
          }));
          setTransitions((prev) => ({
            ...prev,
            [data.id]: false,
          }));
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              setTransitions((prev) => ({
                ...prev,
                [data.id]: true,
              }));
              setTransforms((prevTransforms) => ({
                ...prevTransforms,
                [data.id]: 0,
              }));
            });
          });
          return;
        }
      }
    });
  };

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const clickedItem = event.target;
    if (!(clickedItem instanceof HTMLElement)) {
      return;
    }

    const topBar = document.getElementById("top-bar");

    const isInputOrSelect =
      event.target instanceof HTMLInputElement ||
      event.target instanceof HTMLSelectElement ||
      event.target instanceof HTMLTextAreaElement ||
      lastActiveElement instanceof HTMLTextAreaElement ||
      lastActiveElement instanceof HTMLInputElement ||
      lastActiveElement instanceof HTMLSelectElement;

    if (isInputOrSelect) {
      setLastActiveElement(clickedItem);
      return;
    }

    if (topBar?.contains(clickedItem)) {
      return;
    }

    const isClickOutside = canvasRef.current.state.every(
      (data) => data.ref.current && !data.ref.current.contains(clickedItem),
    );

    if (isClickOutside) {
      setLastClickedObject(null);
      for (const data of canvasRef.current.state) {
        data.selected = false;
        for (const object of data.objects) {
          object.state.selected = false;
        }
        draw(data);
      }
      setLastActiveElement(null);
    }
    canvasRef.current.events.emit("canvasChanged");
  };

  return (
    <div
      className="flex flex-col grow h-full"
      role="presentation"
      onMouseDown={handleOutsideClick}
    >
      <TopBar
        canvasRef={canvasRef}
        // eslint-disable-next-line
        projectId={Number(id)!}
      />
      <div
        role="presentation"
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        className="flex flex-row justify-center items-center grow gap-8 flex-wrap p-8 bg-[#111111]"
      >
        {canvasRef.current.state.map((data, index) => (
          <Canvas
            key={data.id.toString()}
            onMouseDown={(event) => {
              handleMouseDown(event, data);
            }}
            canvasRef={canvasRef}
            lastClickedObject={lastClickedObject}
            setLastClickedObject={setLastClickedObject}
            index={index}
            ref={canvasRef.current.state[index].ref}
            style={{
              transform: `translateX(${transforms[data.id] || 0}px)`,
              transition: transitions[data.id] ? "transform 0.3s ease" : "none",
              opacity: draggedCanvas === data.id ? 0.7 : 1,
              zIndex: draggedCanvas === data.id ? 1 : 0,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Edit;
