import React, { forwardRef, MutableRefObject, useRef, useState } from "react";
import { Property } from "csstype";
import { CanvasData } from "../types.js";
import useDraw from "../hooks/useDraw";
import { isTextObject } from "../util/typeguards";

interface CanvasProps
  extends Omit<
    React.HTMLAttributes<HTMLDivElement>,
    "width" | "height" | "ref" | "onMouseMove" | "onMouseUp"
  > {
  canvasRef: MutableRefObject<CanvasData>;
  lastClickedObject: symbol | null;
  setLastClickedObject: React.Dispatch<React.SetStateAction<symbol | null>>;
  index: number;
}

const Canvas = forwardRef<HTMLCanvasElement | null, CanvasProps>(
  (
    {
      canvasRef,
      lastClickedObject,
      setLastClickedObject,
      index,
      ...props
    }: CanvasProps,
    ref,
  ) => {
    const [cursor, setCursor] = useState<Property.Cursor>("");
    const actionRef = useRef<"left" | "right" | "top" | "bottom" | "move" | "">(
      "",
    );
    const draw = useDraw();

    const scale =
      canvasRef.current.state[index].bgImg.state.img.naturalWidth /
      (canvasRef.current.state[index].ref.current?.getBoundingClientRect()
        .width ?? 1);

    const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
      const canvas = canvasRef.current.state[index].ref.current;
      if (!canvas) return;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      const rect = canvas.getBoundingClientRect();
      const clickX = (e.clientX - rect.left) * scale;
      const clickY = (e.clientY - rect.top) * scale;

      const clickedObject = canvasRef.current.state[index].objects.find(
        (obj) => {
          obj.state.width += 0.03 * canvas.width;
          obj.state.height += 0.03 * canvas.width;
          const r = obj.containsPoint(clickX, clickY);
          obj.state.width -= 0.03 * canvas.width;
          obj.state.height -= 0.03 * canvas.width;
          return r;
        },
      );

      const selectMultiple = e.ctrlKey || e.shiftKey || e.metaKey;

      if (!clickedObject) {
        setLastClickedObject(null);
      }

      if (!selectMultiple || !clickedObject) {
        for (const data of canvasRef.current.state) {
          for (const object of data.objects) {
            object.state.selected = false;
          }
        }
      }

      if (clickedObject) {
        clickedObject.state.selected = true;
        for (const data of canvasRef.current.state) {
          data.selected = false;
        }
      } else {
        if (!selectMultiple) {
          for (const state of canvasRef.current.state) {
            state.selected = false;
          }
        }
        canvasRef.current.state[index].selected = true;
      }

      for (const state of canvasRef.current.state) {
        draw(state);
      }

      canvasRef.current.events.emit("canvasChanged");
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
      const canvas = canvasRef.current.state[index].ref.current;
      if (!canvas) return;

      const rect = canvas.getBoundingClientRect();
      const mouseX = (e.clientX - rect.left) * scale;
      const mouseY = (e.clientY - rect.top) * scale;

      const threshold = canvas.width * 0.03;

      const hoveredObject = canvasRef.current.state[index].objects.find(
        (obj) => {
          obj.state.width += threshold;
          obj.state.height += threshold;
          const r = obj.containsPoint(mouseX, mouseY);
          obj.state.width -= threshold;
          obj.state.height -= threshold;
          return r;
        },
      );

      if (hoveredObject && e.buttons !== 1) {
        const leftEdge =
          hoveredObject.properties.x - hoveredObject.state.width / 2;
        const rightEdge =
          hoveredObject.properties.x + hoveredObject.state.width / 2;
        const topEdge =
          hoveredObject.properties.y - hoveredObject.state.height / 2;
        const bottomEdge =
          hoveredObject.properties.y + hoveredObject.state.height / 2;
        if (Math.abs(mouseX - leftEdge) <= threshold) {
          actionRef.current = "left";
        } else if (Math.abs(mouseX - rightEdge) <= threshold) {
          actionRef.current = "right";
        } else if (Math.abs(mouseY - topEdge) <= threshold) {
          actionRef.current = "top";
        } else if (Math.abs(mouseY - bottomEdge) <= threshold) {
          actionRef.current = "bottom";
        } else {
          actionRef.current = "move";
        }
      }

      setCursor(
        hoveredObject?.state.selected
          ? actionRef.current === "left" || actionRef.current === "right"
            ? "ew-resize"
            : actionRef.current === "top" || actionRef.current === "bottom"
              ? "ns-resize"
              : "move"
          : hoveredObject
            ? "pointer"
            : canvasRef.current.state[index].selected
              ? "move"
              : "pointer",
      );

      if (e.buttons !== 1) return;

      const canvasWidth = canvas.width;
      const middleX = canvasWidth / 2;

      for (const data of canvasRef.current.state) {
        for (const obj of data.objects) {
          const leftEdge = obj.properties.x - obj.state.width / 2;
          const rightEdge = obj.properties.x + obj.state.width / 2;
          const topEdge = obj.properties.y - obj.state.height / 2;
          const bottomEdge = obj.properties.y + obj.state.height / 2;
          const isNearCenter = Math.abs(obj.state.x - middleX) < 20;
          if (
            obj.state.selected &&
            (!isTextObject(obj) || !obj.state.editable)
          ) {
            if (actionRef.current === "left") {
              const newWidth = rightEdge - mouseX;
              if (newWidth > 5) {
                obj.state.width = newWidth;
                obj.state.x = mouseX + newWidth / 2;
                obj.properties.x = obj.state.x;
              }
            } else if (actionRef.current === "right") {
              const newWidth = mouseX - leftEdge;
              if (newWidth > 5) {
                obj.state.width = newWidth;
                obj.state.x = leftEdge + newWidth / 2;
                obj.properties.x = obj.state.x;
              }
            } else if (actionRef.current === "top") {
              const newHeight = bottomEdge - mouseY;
              if (newHeight > 5) {
                obj.state.height = newHeight;
                obj.state.y = mouseY + newHeight / 2;
                obj.properties.y = obj.state.y;
              }
            } else if (actionRef.current === "bottom") {
              const newHeight = mouseY - topEdge;
              if (newHeight > 5) {
                obj.state.height = newHeight;
                obj.state.y = topEdge + newHeight / 2;
                obj.properties.y = obj.state.y;
              }
            } else {
              obj.state.x = mouseX;
              obj.state.y = mouseY;
              obj.properties.y = obj.state.y;
              if (!isNearCenter) {
                obj.properties.x = obj.state.x;
              } else {
                obj.properties.x = middleX;
              }
            }
            obj.state.dragging = true;
          }
        }
        draw(data);
      }
    };

    const handleMouseUp = (e: React.MouseEvent<HTMLCanvasElement>) => {
      const canvas = canvasRef.current.state[index].ref.current;
      if (!canvas) return;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      const rect = canvas.getBoundingClientRect();
      const clickX = (e.clientX - rect.left) * scale;
      const clickY = (e.clientY - rect.top) * scale;

      const clickedObject = canvasRef.current.state[index].objects.find(
        (obj) => {
          obj.state.width += 0.03 * canvas.width;
          obj.state.height += 0.03 * canvas.width;
          const r = obj.containsPoint(clickX, clickY);
          obj.state.width -= 0.03 * canvas.width;
          obj.state.height -= 0.03 * canvas.width;
          return r;
        },
      );

      if (!clickedObject) return;

      if (
        clickedObject.id === lastClickedObject &&
        isTextObject(clickedObject) &&
        !clickedObject.state.dragging
      ) {
        clickedObject.state.editable = true;
      }

      actionRef.current = "";

      for (const data of canvasRef.current.state) {
        for (const obj of data.objects) {
          obj.state.dragging = false;
        }
        draw(data);
      }

      setLastClickedObject(clickedObject.id);
      canvasRef.current.events.emit("canvasChanged");
    };

    const textObjects = canvasRef.current.state[index].objects
      .filter(isTextObject)
      .filter((obj) => obj.state.editable);

    return (
      <div className="flex relative" {...props}>
        <canvas
          className={`w-[300px] h-[${(16 / 9) * 300}px]`}
          style={{ cursor }}
          ref={ref}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        />
        {textObjects.map((textObj, i) => (
          <textarea
            key={i}
            wrap="off"
            rows={1}
            defaultValue={textObj.properties.text}
            onChange={(e) => {
              textObj.properties.text = e.target.value;
              e.currentTarget.style.height = "auto";
              e.currentTarget.style.width = "auto";
              e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
              e.currentTarget.style.width = `${e.currentTarget.scrollWidth}px`;
              draw(canvasRef.current.state[index]);
            }}
            style={{
              left: textObj.properties.x * (1 / scale),
              top: textObj.properties.y * (1 / scale),
              fontFamily: textObj.properties.fontFamily,
              fontSize: textObj.properties.fontSize * (1 / scale),
            }}
            className={`leading-[1.2] absolute transform -translate-x-1/2 -translate-y-1/2 text-center whitespace-pre outline-none overflow-hidden resize-none p-0 border-none text-transparent bg-transparent caret-black`}
            onFocus={(e) => {
              e.target.setSelectionRange(0, e.target.value.length);
              e.currentTarget.style.height = "auto";
              e.currentTarget.style.width = "auto";
              e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
              e.currentTarget.style.width = `${e.currentTarget.scrollWidth}px`;
            }}
            onBlur={() => {
              textObj.state.editable = false;
            }}
            autoFocus
          />
        ))}
      </div>
    );
  },
);

Canvas.displayName = "Canvas";

export default Canvas;
