import { createRef, MutableRefObject, useRef, useState } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { DriveFile } from "@tikifu/shared/types";
import { CanvasObject } from "../classes/CanvasObject";
import { TextObject } from "../classes/TextObject";
import textPropertiesConfig from "../configs/textObjectConfig";
import { isTextObject } from "../util/typeguards";
import { TextProperties, CanvasData, CanvasRefState } from "../types";
import { ImageObject } from "../classes/ImageObject";
import useCanvasRef from "../hooks/useCanvasRef";
import useDraw from "../hooks/useDraw";
import { createDriveFile, uploadTiktokSlideshow } from "../services/upload";
import Button from "./Button";
import ColorPicker from "./ColorPicker";
import Dropdown from "./Dropdown";
import Modal from "./Modal";
import Timeline from "./Timeline";

interface TopBarProps {
  canvasRef: MutableRefObject<CanvasData>;
  projectId: number;
}

const TopBar = ({ canvasRef }: TopBarProps) => {
  const canvasState = useCanvasRef(canvasRef);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [schedulingModalOpen, setSchedulingModalOpen] = useState(false);
  const [downloadAmount, setDownloadAmount] = useState(1);
  const [downloadMaxAmount, setDownloadMaxAmount] = useState(1);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const folderFileInputRef = useRef<HTMLInputElement>(null);
  const singleFileInputRef = useRef<HTMLInputElement>(null);
  const textFileInputRef = useRef<HTMLInputElement>(null);
  const draw = useDraw();

  const selectedObjects = canvasState
    .map((data) => data.objects)
    .flat()
    .filter((obj) => obj.state.selected);

  const showCanvasOptions = canvasRef.current.state.find(
    (data) => data.selected,
  );

  const showRandomizeImage = canvasState.find(
    (data) => data.bgImg.properties.sources.length > 1 && data.selected,
  );

  const showRandomizeText = selectedObjects.some(
    (obj) => isTextObject(obj) && obj.properties.texts.length > 1,
  );

  const uploadToTikTok = async () => {
    const promises = canvasState.map((data) => {
      return new Promise<DriveFile>((resolve) => {
        const canvas = data.ref.current;
        if (!canvas) return;
        canvas.toBlob((blob) => {
          if (!blob) return;
          void createDriveFile(blob).then(resolve);
        }, "image/jpeg");
      });
    });

    const files = await Promise.all(promises);
    const imageUrls = files.map(
      (file) => `https://assets.tikifu.com/${file.id}`,
    );

    try {
      await uploadTiktokSlideshow({ title, description, imageUrls });
    } catch (e) {
      console.error(e);
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files ?? []);
    const imageUrls = files.map((file) => URL.createObjectURL(file));

    const newCanvasState: CanvasRefState = {
      id: Symbol(canvasState.length),
      ref: createRef<HTMLCanvasElement>(),
      objects: [],
      bgImg: new ImageObject(imageUrls, { x: 0, y: 0 }, "auto", "auto"),
      selected: false,
    };

    {
      /*createSlide({ projectId, images: files })
      .then((data) => {
        newCanvasData.dbId = data.id;
      })
      .catch((e: unknown) => {
        console.error(e);
      });*/
    }

    canvasRef.current.state = [...canvasRef.current.state, newCanvasState];
    newCanvasState.bgImg.state.img.onload = () => {
      canvasRef.current.events.emit("canvasChanged");
      draw(newCanvasState);
    };
    newCanvasState.bgImg.state.img.src = newCanvasState.bgImg.properties.source;
    event.target.value = "";
  };

  const handleAddSlide = (option: string) => {
    if (option === "Folder") {
      folderFileInputRef.current?.click();
    } else {
      singleFileInputRef.current?.click();
    }
  };

  const handleChange = (property: string, value: string | boolean) => {
    for (const data of canvasState) {
      for (const obj of data.objects) {
        if (obj.state.selected && property in obj.properties) {
          // @ts-expect-error Can't index properties with a string
          obj.properties[property] = value;
          if (property === "fontFamily" && isTextObject(obj)) {
            obj.loadFont(() => {
              draw(data);
            });
          }
        }
      }
      draw(data);
    }
  };

  const getCommonValue = (
    property: string,
    typeguard: (obj: CanvasObject) => obj is TextObject,
  ) => {
    if (selectedObjects.length === 0) return "";
    const specificSelectedObjects = selectedObjects.filter(typeguard);
    const uniqueProperties = new Set<string | string[] | number | boolean>();
    specificSelectedObjects.forEach((obj) =>
      uniqueProperties.add(obj.properties[property as keyof TextProperties]),
    );
    return uniqueProperties.size === 1 ? [...uniqueProperties][0] : "";
  };

  const handleAddSingleText = (texts: string[]) => {
    for (const data of canvasState) {
      if (data.selected) {
        const newTextObj = new TextObject([...texts]);
        data.objects.push(newTextObj);
        newTextObj.loadFont(() => {
          draw(data);
        });

        if (!data.dbId) return;

        {
          /*
        try {
          await createObject({
            slideId: data.dbId,
            properties: newTextObj.properties,
          });
        } catch (e: unknown) {
          console.error(e);
        }
          */
        }
      }
    }
  };

  const handleAddTextFromFile = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target?.result;
      if (text && typeof text === "string") {
        handleAddSingleText(text.split(/\r?\n/));
      }
    };
    reader.readAsText(file);
  };

  const handleAddText = (option: string) => {
    if (option === "File") {
      textFileInputRef.current?.click();
    } else {
      handleAddSingleText(["new text"]);
    }
  };

  const handleRandomizeImage = () => {
    for (const data of canvasState) {
      if (data.selected) {
        data.bgImg.properties.source =
          data.bgImg.properties.sources[
            Math.floor(Math.random() * data.bgImg.properties.sources.length)
          ];
        data.bgImg.state.img.onload = () => {
          draw(data);
        };
        data.bgImg.state.img.src = data.bgImg.properties.source;
      }
    }
  };

  const handleDownloadSlideshows = async (
    _event: React.MouseEvent<HTMLButtonElement>,
    download: boolean,
  ) => {
    const zip = new JSZip();
    // eslint-disable-next-line
    const drivePromises: Promise<DriveFile>[] = [];
    let combinationIndex = 0;

    const generateImageCombinations = async (canvasIndex: number) => {
      if (combinationIndex === downloadAmount) return;
      if (canvasIndex >= canvasState.length) {
        await new Promise((resolve) => requestAnimationFrame(resolve));
        const folder = zip.folder(`${combinationIndex}`);
        const capturePromises = canvasState.map((data, i) => {
          return new Promise<void>((resolve) => {
            if (!folder || !data.ref.current) return;
            data.ref.current.toBlob((blob) => {
              if (!blob) return;
              if (download) {
                folder.file(`${i}.jpg`, blob);
              } else {
                drivePromises.push(createDriveFile(blob));
              }
              resolve();
            });
          });
        });
        await Promise.all(capturePromises);
        combinationIndex++;
        return;
      }

      const data = canvasState[canvasIndex];
      for (const source of data.bgImg.properties.sources) {
        await new Promise<void>((resolve) => {
          data.bgImg.state.img.onload = () => {
            draw(data);
            resolve();
          };
          data.bgImg.state.img.src = source;
        });
        await generateImageCombinations(canvasIndex + 1);
      }
    };

    const generateCombinations = async (canvasIndex: number) => {
      if (canvasIndex >= canvasState.length) {
        return;
      }
      const data = canvasState[canvasIndex];
      if (!data.objects.length) {
        if (canvasIndex === 0) {
          await generateImageCombinations(0);
        }
        await generateCombinations(canvasIndex + 1);
      } else {
        for (const obj of data.objects) {
          if (!isTextObject(obj) || obj.properties.texts.length === 1) {
            continue;
          }
          for (const text of obj.properties.texts) {
            obj.properties.text = text;
            obj.properties.fontSize = 0;
            await generateImageCombinations(0);
            await generateCombinations(canvasIndex + 1);
          }
        }
      }
    };

    await generateCombinations(0);
    if (download) {
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, "slideshows.zip");
    } else {
      await Promise.all(drivePromises);
    }
  };

  const handleRandomizeText = () => {
    for (const data of canvasState) {
      for (const obj of data.objects) {
        if (obj.state.selected && isTextObject(obj)) {
          obj.properties.fontSize = 0;
          obj.properties.text =
            obj.properties.texts[
              Math.floor(Math.random() * obj.properties.texts.length)
            ];
        }
      }
      draw(data);
    }
  };

  const handleMakeUnique = () => {
    for (const data of canvasState) {
      if (data.selected) {
        const canvas = data.ref.current;
        if (!canvas) return;
        const ctx = canvas.getContext("2d");
        if (!ctx) return;
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const randomX = Math.floor(Math.random() * canvas.width);
        const randomY = Math.floor(Math.random() * canvas.height);
        const index = (randomY * canvas.width + randomX) * 4;
        imageData.data[index] = 0;
        imageData.data[index + 1] = 0;
        imageData.data[index + 2] = 0;
        imageData.data[index + 3] = 255;
        ctx.putImageData(imageData, 0, 0);
      }
      draw(data);
    }
  };

  const handleRemove = () => {
    for (const data of canvasState) {
      if (data.selected) {
        canvasRef.current.state = canvasRef.current.state.filter(
          (canvas) => canvas.id !== data.id,
        );
      }
    }
    canvasRef.current.events.emit("canvasChanged");
  };

  return (
    <>
      <Modal
        isOpen={uploadModalOpen}
        onClose={() => {
          setUploadModalOpen(false);
        }}
      >
        <h2>Title</h2>
        <input
          type="text"
          className="bg-transparent text-black border border-black py-1 px-2 rounded cursor-pointer transition-all duration-300 ease-in-out text-base"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
        <h2>Description</h2>
        <textarea
          className="bg-transparent text-black border border-black py-1 px-2 rounded cursor-pointer transition-all duration-300 ease-in-out text-base"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          rows={5}
        />
        <Button color="black" onClick={() => void uploadToTikTok()}>
          Upload as draft
        </Button>
      </Modal>
      <Modal
        isOpen={downloadModalOpen}
        onClose={() => {
          setDownloadModalOpen(false);
        }}
      >
        <label htmlFor="slider">Slideshows amount: {downloadAmount}</label>
        <input
          type="range"
          id="slider"
          min="1" // Minimum value
          max={downloadMaxAmount} // Maximum value
          step="1" // Step size
          value={downloadAmount} // Current value of the slider
          onChange={(e) => {
            setDownloadAmount(Number(e.target.value));
          }} // Handler for slider change
        />
        <Button
          color="black"
          onClick={(e) => void handleDownloadSlideshows(e, true)}
        >
          Download
        </Button>
      </Modal>
      <Modal
        isOpen={schedulingModalOpen}
        onClose={() => {
          setSchedulingModalOpen(false);
        }}
      >
        <Timeline />
        <Button
          color="black"
          onClick={(e) => void handleDownloadSlideshows(e, false)}
        >
          Schedule
        </Button>
      </Modal>
      <div
        id="top-bar"
        className="sticky top-0 left-0 w-full bg-gray-800 text-white flex flex-wrap items-center justify-center gap-4 py-3 min-h-[61px] box-border z-[1000]"
      >
        {showCanvasOptions && (
          <>
            <Dropdown
              options={["File", "Single"]}
              onSelect={handleAddText}
              defaultValue="Add Text"
            />
            <input
              className="hidden"
              ref={textFileInputRef}
              type="file"
              onChange={handleAddTextFromFile}
            />
            <Button onClick={handleMakeUnique}>Make unique</Button>
            {showRandomizeImage && (
              <Button onClick={handleRandomizeImage}>Randomize</Button>
            )}
            <Button onClick={handleRemove}>Remove</Button>
          </>
        )}

        {selectedObjects.length === 0 && !showCanvasOptions && (
          <>
            <Dropdown
              options={["Folder", "Single"]}
              onSelect={handleAddSlide}
              defaultValue="Add Slide"
            />
            <input
              ref={folderFileInputRef}
              type="file"
              accept="image/*"
              className="hidden"
              multiple
              // @ts-expect-error Webkitdirectory is not recognized by TypeScript
              webkitdirectory="true"
              onChange={handleFileSelect}
            />
            <input
              ref={singleFileInputRef}
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleFileSelect}
            />
            <Button
              onClick={() => {
                setUploadModalOpen(true);
              }}
            >
              Upload
            </Button>
            <Button
              onClick={() => {
                const maxCombinations = canvasState.reduce(
                  (combinations, data) => {
                    const variations =
                      data.bgImg.properties.sources.length *
                      data.objects.reduce((amount, obj) => {
                        if (!isTextObject(obj)) return amount;
                        return obj.properties.texts.length * amount;
                      }, 1);
                    return combinations * variations;
                  },
                  1,
                );
                setDownloadModalOpen(true);
                setDownloadMaxAmount(maxCombinations);
              }}
            >
              Download
            </Button>
            <Button
              onClick={() => {
                setSchedulingModalOpen(true);
              }}
            >
              Schedule
            </Button>
          </>
        )}
        {selectedObjects.length > 0 && (
          <>
            {Object.keys(textPropertiesConfig).map((property) => {
              const config =
                textPropertiesConfig[
                  property as keyof typeof textPropertiesConfig
                ];
              const commonValue = getCommonValue(property, isTextObject);

              return (
                <div
                  key={property}
                  className="flex flex-row gap-2 items-center"
                >
                  <label>{config.label}</label>
                  {typeof commonValue === "boolean" ? (
                    <input
                      key={String(commonValue)}
                      type="checkbox"
                      defaultChecked={commonValue}
                      onChange={(e) => {
                        handleChange(property, e.target.checked);
                      }}
                    />
                  ) : config.type === "select" && config.options ? (
                    <select
                      key={String(commonValue)}
                      defaultValue={commonValue}
                      className="bg-transparent text-white border border-white py-1 px-2 rounded cursor-pointer transition-all duration-300 ease-in-out text-base outline-none hover:bg-white hover:text-gray-900 hover:border-white focus:bg-white focus:text-gray-900 focus:border-white"
                      onChange={(e) => {
                        handleChange(property, e.target.value);
                      }}
                    >
                      {config.options.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  ) : config.type === "color" ? (
                    <ColorPicker
                      key={String(commonValue)}
                      defaultValue={
                        typeof commonValue === "string"
                          ? commonValue
                          : "#000000"
                      }
                      onChange={(color) => {
                        handleChange(property, color);
                      }}
                    />
                  ) : (
                    <input
                      key={String(commonValue)}
                      type={config.type}
                      className="bg-transparent text-white border border-white py-1 px-2 rounded cursor-pointer transition-all duration-300 ease-in-out text-base outline-none hover:bg-white hover:text-gray-900 hover:border-white focus:bg-white focus:text-gray-900 focus:border-white"
                      min={config.min}
                      max={config.max}
                      step={config.step}
                      defaultValue={commonValue}
                      onChange={(e) => {
                        handleChange(property, e.target.value);
                      }}
                    />
                  )}
                </div>
              );
            })}
            {showRandomizeText && (
              <Button onClick={handleRandomizeText}>Randomize</Button>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TopBar;
