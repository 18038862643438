export class EventEmitter<T extends string> {
  listeners: Record<string, (() => void)[]> = {};

  subscribe(event: T, callback: () => void) {
    // eslint-disable-next-line
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(callback);
    return () => {
      this.listeners[event] = this.listeners[event].filter(
        (listener) => listener !== callback,
      );
    };
  }

  emit(event: T) {
    // eslint-disable-next-line
    if (this.listeners[event]) {
      this.listeners[event].forEach((callback) => {
        callback();
      });
    }
  }
}
