import { Login, AccessToken, UserToken } from "@tikifu/shared/types";
import { API_URL } from "../util/config";
import { post } from "../util/req";

const tiktokLogin = (body: Login) =>
  post<AccessToken>(`${API_URL}/auth/tiktok/login`, body);

const googleLogin = (body?: Login) =>
  post<AccessToken>(`${API_URL}/auth/google/login`, body);

const refreshGoogleLogin = () =>
  post<UserToken>(`${API_URL}/auth/google/refresh`);

const refreshTiktokLogin = () =>
  post<AccessToken>(`${API_URL}/auth/tiktok/refresh`);

export { tiktokLogin, googleLogin, refreshGoogleLogin, refreshTiktokLogin };
