import {
  Route,
  Routes,
  Navigate,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Edit from "./views/Edit";
import Login from "./views/Login";
import {
  googleLogin,
  refreshGoogleLogin,
  refreshTiktokLogin,
  tiktokLogin,
} from "./services/auth";
import { GOOGLE_API_KEY, TIKTOK_AUTH_URL } from "./util/config";
import { useUserContext, useTokenContext } from "./util/contexts";
import "./index.css";
import Loading from "./views/Loading";

const loadGapiClient = () => {
  return new Promise<void>((resolve, reject) => {
    const initGapiClient = async () => {
      try {
        await gapi.client.init({
          apiKey: GOOGLE_API_KEY,
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
          ],
        });
        resolve();
      } catch (e) {
        console.error(e);
        reject(new Error("Loading gapi failed"));
      }
    };

    gapi.load("client", () => void initGapiClient());
  });
};

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const { setTiktokAccessToken } = useTokenContext();
  const { setUser } = useUserContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchParams.get("code");
  const scope = searchParams.get("scope");

  useEffect(() => {
    const authenticate = async () => {
      try {
        let tiktokTokenSet = false;
        if (code) {
          if (scope) {
            await googleLogin({ code });
            window.location.href = TIKTOK_AUTH_URL;
          } else {
            const { accessToken } = await tiktokLogin({ code });
            setTiktokAccessToken(accessToken);
            tiktokTokenSet = true;
          }
        }
        const user = await refreshGoogleLogin();
        setUser(user);

        await loadGapiClient();
        gapi.client.setToken({
          access_token: user.accessToken,
        });
        if (!tiktokTokenSet) {
          const { accessToken } = await refreshTiktokLogin();
          setTiktokAccessToken(accessToken);
        }
        setAuthenticated(true);
        navigate("/", { replace: true });
      } catch {
        setLoginFailed(true);
      }
    };

    void authenticate();
  }, [code, scope]);

  return (
    <Routes>
      {authenticated ? (
        <>
          <Route path="/" element={<Edit />} />
          <Route path="*" element={<Navigate to="/" />} />
        </>
      ) : (
        <>
          <Route path="/login" element={<Login />} />
          <Route
            path="*"
            element={loginFailed ? <Navigate to="/login" /> : <Loading />}
          />
        </>
      )}
    </Routes>
  );
};

export default App;
