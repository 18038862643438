import { createRoot } from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { TokenProvider, UserProvider } from "./util/contexts";

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <UserProvider>
      <TokenProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </TokenProvider>
    </UserProvider>,
  );
} else {
  console.error(
    "Root element not found. Make sure there is an element with id 'root' in your HTML.",
  );
}
