import { TextProperties } from "@tikifu/shared/types";
import { CanvasState, ImageProperties } from "../types";

export abstract class CanvasObject {
  id = Symbol();
  serverId: number | undefined = undefined;
  abstract type: string;
  abstract properties: TextProperties | ImageProperties;
  abstract state: CanvasState;

  abstract draw(ctx: CanvasRenderingContext2D): void;

  abstract containsPoint(x: number, y: number): boolean;
}
