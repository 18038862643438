import logo from "../logo_white.svg"; // Adjust the import path to your logo

const Loading = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-[#111111]">
      <img className="w-40 h-auto animate-pulse" src={logo} alt="Logo" />
    </div>
  );
};

export default Loading;
