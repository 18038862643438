import { ImageProperties, ImageState } from "../types";
import { CanvasObject } from "./CanvasObject";

export class ImageObject extends CanvasObject {
  type = "ImageObject";
  state: ImageState;
  properties: ImageProperties;

  constructor(
    sources: string[],
    position: { x: number; y: number },
    width: number | "auto" | "fill",
    height: number | "auto" | "fill",
  ) {
    super();
    this.properties = {
      x: position.x,
      y: position.y,
      width,
      height,
      source: sources[0],
      sources,
    };
    this.state = {
      x: position.x,
      y: position.y,
      img: new Image(),
      width: 0,
      height: 0,
      dragging: false,
      selected: false,
    };
    this.state.img.crossOrigin = "anonymous";
  }

  draw(ctx: CanvasRenderingContext2D): void {
    this.state.width =
      this.properties.width === "auto"
        ? this.state.img.naturalWidth
        : this.properties.width === "fill"
          ? ctx.canvas.width
          : this.properties.width;
    this.state.height =
      this.properties.height === "auto"
        ? this.state.img.naturalHeight
        : this.properties.height === "fill"
          ? ctx.canvas.height
          : this.properties.height;

    this.properties.y = (ctx.canvas.height - this.state.height) / 2;

    ctx.drawImage(
      this.state.img,
      this.properties.x,
      this.properties.y,
      this.state.width,
      this.state.height,
    );
  }

  containsPoint(x: number, y: number): boolean {
    return (
      x >= this.properties.x &&
      x <= this.properties.x + this.state.width &&
      y >= this.properties.y &&
      y <= this.properties.y + this.state.height
    );
  }
}
