import React, { ReactNode } from "react";

interface ButtonProps {
  color?: "white" | "black";
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  color = "white",
  onClick = () => null,
  children,
  className = "",
}) => {
  const baseClasses =
    "px-2 py-1 rounded transition duration-300 ease-in-out font-medium outline-none";
  const colorClasses =
    color === "white"
      ? "text-white border border-white hover:bg-white hover:text-gray-900"
      : "text-black border border-black hover:bg-black hover:text-white";

  return (
    <button
      className={`${baseClasses} ${colorClasses} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
