import { useState, useEffect, useRef } from "react";
import Button from "./Button";

interface DropdownProps {
  options: string[];
  onSelect: (option: string) => void;
  defaultValue: string;
}

const Dropdown = ({ options, onSelect, defaultValue }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: string) => {
    onSelect(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="relative" ref={dropdownRef}>
      <Button color="white" onClick={handleToggle}>
        {defaultValue}
      </Button>

      {isOpen && (
        <div className="absolute left-0 mt-1 w-full bg-gray-800 text-white border border-white rounded-md shadow-lg z-10">
          {options.map((option, index) => (
            <button
              key={index}
              className="w-full px-3 py-2 text-left hover:bg-white hover:text-gray-900 transition-colors duration-200"
              onClick={() => {
                handleSelect(option);
              }}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
