import { useState } from "react";

const Timeline = () => {
  // Define the 3-hour blocks for 24 hours
  const hours = Array.from({ length: 8 }, (_, i) => i * 3); // [0, 3, 6, ..., 21]
  const [selectedBlocks, setSelectedBlocks] = useState(new Set());

  // Toggle block selection
  const toggleBlock = (hour: number) => {
    setSelectedBlocks((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(hour)) {
        newSet.delete(hour);
      } else {
        newSet.add(hour);
      }
      return newSet;
    });
  };

  return (
    <>
      <h2 className="text-xl font-semibold">24-Hour Timeline</h2>
      <p>
        Choose during which three hour time windows your slideshows should be
        posted each day.
      </p>
      <div className="flex gap-2 items-center">
        <span>00:00</span>
        {hours.map((hour) => (
          <div
            role="presentation"
            key={hour}
            onClick={() => {
              toggleBlock(hour);
            }}
            className={`cursor-pointer w-10 h-10 flex items-center justify-center border rounded-lg ${
              selectedBlocks.has(hour)
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
          />
        ))}
        <span>00:00</span>
      </div>
    </>
  );
};

export default Timeline;
